import './App.css';
import logo from './logo.svg';
import totp from 'totp-generator';
import {useEffect, useState} from "react";

function App() {
    const [token, setToken] = useState();
    const [second, setSecond] = useState();
    const [minute, setMinute] = useState();

    function startTime() {
        const today = new Date();
        let m = today.getMinutes();
        let s = today.getSeconds();
        setSecond(s);
        if (minute !== m) {
            setMinute(m);
            initToken();
        }
        setTimeout(startTime, 1000);
    }

    const isDark = window.matchMedia("(prefers-color-scheme:dark)").matches

    const lightTheme = {
        backgroundColor: '#F8F8E7',
        color: '#000099'
    }

    const darkTheme = {
        backgroundColor: '#001f3f',
        color: '#FFF9C4'
    }

    const initToken = () => {
        getSecret().then(secret => setToken(getToken(secret)));
    }

    // eslint-disable-next-line
    useEffect(() => {startTime();}, []);

    const getSecret = async () => {
        const cache = await caches.open('my-cache');
        const response = await cache.match('/secret');

        if (!response) {
            return null;
        }

        const responseBody = await response.json();

        if (!responseBody['secret']) {
            return null;
        }

        return responseBody['secret'];
    };

    const saveSecret = (secret) => {
        caches.open('my-cache').then(async (cache) => {
            const response = new Response(JSON.stringify({secret: secret}));
            await cache.put('/secret', response);
            setToken(getToken(secret));
        });
    };

    const deleteSecret = () => {
        caches.open('my-cache').then(async (cache) => {
            await cache.delete('/secret');
            setToken(null);
        });
    };

    const getToken = secret => {
        if (!secret) return null;
        try {
            return totp(secret, {period: 60});
        } catch (e) {
            alert("Invalid secret key!");
            deleteSecret();
            return null;
        }
    }

    return (
        <div className="App" style={{...isDark ? darkTheme : lightTheme}}>
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                {token === undefined ? (<></>) : !token ? (
                    <div style={{display: "block"}}>
                        <div style={{paddingTop: 30}}>
                            <input id="secretInput" style={{width: 100}} placeholder={"secret"}/>
                        </div>
                        <div style={{paddingTop: 10}}>
                            <button onClick={(t) => {
                                saveSecret(document.getElementById("secretInput").value);
                            }} style={{width: 100}}>OK
                            </button>
                        </div>
                    </div>) : (<div style={{display: "block"}}>
                    <p style={{fontSize: '2.2rem'}} onClick={(t) => {
                        navigator.clipboard.writeText(token)
                            .then(r => {
                                t.target.innerHTML = 'copied!';
                                setTimeout(() => t.target.innerHTML = token, 1000);
                            });
                    }}>{token}</p>
                    <progress value={second} max={60}/>
                    <div style={{paddingTop: 20}}>
                        <button onClick={() => {
                            // eslint-disable-next-line no-restricted-globals
                            let response = confirm("Are you sure?");
                            if (response) {
                                deleteSecret();
                            }
                        }} style={{width: 100}}>Clear Secret
                        </button>
                    </div>
                </div>)
                }
            </header>
        </div>
    )
        ;
}

export default App;
